import { DefaultAxiosDownloadTrigger } from "./ApiBase";

/* export type KeyMetricsProps = {
   periods: string[];
   values: number[];
   title: string;
   entity: string;
}; */

export interface ReportedValuesDto {
   periods: string[];
   values: number[];
   title: string;
   entity: string;
   axisTitle: string;
}

export default async function DownloadKeyMetrics(values: ReportedValuesDto[]) {
   if (values.length === 0) return;
   //Show maximum of 12 periods in one chart
   values.forEach((v) => {
      v.values = v.values.splice(-12);
      v.periods = v.periods.splice(-12);
   });

   DefaultAxiosDownloadTrigger(`CreateKeyMetricsPresentation`, `Presentation`, values);
}

export async function DownloadGriegConnectPresentation(values: ReportedValuesDto[]) {
   if (values.length === 0) return;
   //Show maximum of 12 periods in one chart
   values.forEach((v) => {
      v.values = v.values.splice(-12);
      v.periods = v.periods.splice(-12);
   });

   DefaultAxiosDownloadTrigger(`CreateGriegConnectPresentation`, `Presentation`, values);
}
