//Returns the current period in the format YYYYMM, where MM is the month number (01-12)
//Only returns complete months, so if the current date is 2021-05-15, the period will be 202104
export function getCurrentPeriod(): string {
   const today = new Date();
   const year = today.getFullYear();
   const month = today.getMonth();
   const formattedMonth = month < 10 ? `0${month}` : month.toString();
   return `${year}${formattedMonth}`;
}

export function getFirstPeriodOfCurrentYear(): string {
   const today = new Date();
   const year = today.getFullYear();
   const firstMonth = "00";
   return `${year}${firstMonth}`;
}

export function getCurrentAndFirstPeriodOfYear(): [string, string] {
   return [getCurrentPeriod(), getFirstPeriodOfCurrentYear()];
}

export function getPreviousYearPeriods(): [string, string] {
   const today = new Date();
   const previousYear = today.getFullYear() - 1;
   const currentMonth = today.getMonth();
   const formattedCurrentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth.toString();
   const currentPeriod = `${previousYear}${formattedCurrentMonth}`;
   const firstPeriod = `${previousYear}01`;
   return [currentPeriod, firstPeriod];
}

export function getRelevantPeriods(period: string): string[] {
   const periods: string[] = [];
   const year = `20${period.slice(-2)}`; // Extract the year from the input string
 
   if (period.startsWith("Q")) {
     const quarter = parseInt(period[1]);
     const startMonth = (quarter - 1) * 3 + 1;
 
     for (let i = 0; i < 3; i++) {
       const month = (startMonth + i).toString().padStart(2, '0');
       periods.push(`${year}${month}`);
     }
   } else if (period.startsWith("YTD")) {
     for (let i = 1; i <= 12; i++) {
       const month = i.toString().padStart(2, '0');
       periods.push(`${year}${month}`);
     }
   }
 
   return periods;
 };

 //Takes an input of type "Q1 21" or "YTD 21" and returns the previous year period in the same format
 //For example, if the input is "Q1 21", the output will be "Q1 20"
 export function getPreviousYearPeriodFromString(period: string): string {
  const [periodPart, yearSuffix] = period.split(' ');
  const year = parseInt(`20${yearSuffix}`, 10) - 1;
  const previousYearSuffix = year.toString().slice(-2);

  return `${periodPart} ${previousYearSuffix}`;
}
