import { TrialBalanceAccountDto } from "../api/GetTrialBalance";

const getRelevantPeriods = (period: string): string[] => {
  const periods: string[] = [];
  const year = `20${period.slice(-2)}`; // Extract the year from the input string

  if (period.startsWith("Q")) {
    const quarter = parseInt(period[1]);
    const startMonth = (quarter - 1) * 3 + 1;

    for (let i = 0; i < 3; i++) {
      const month = (startMonth + i).toString().padStart(2, '0');
      periods.push(`${year}${month}`);
    }
  } else if (period.startsWith("YTD")) {
    for (let i = 1; i <= 12; i++) {
      const month = i.toString().padStart(2, '0');
      periods.push(`${year}${month}`);
    }
  } else {
    const monthMap: { [key: string]: string } = {
        'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04',
        'May': '05', 'Jun': '06', 'Jul': '07', 'Aug': '08',
        'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
      };
    const [monthName, yearSuffix] = period.split(' ');
    const month = monthMap[monthName];
    periods.push(`${year}${month}`);
  }

  return periods;
};

export function calculateAmountByPeriodAndAccount(
  accounts: TrialBalanceAccountDto[],
  period: string,
  filter: string
): number {
  const relevantPeriods = getRelevantPeriods(period);
  const ACCOUNT_TYPES_TO_REVERSE = [3,4,5,6,8]
  return accounts
    .filter(account => 
      relevantPeriods.includes(account.period.toString()) &&
      (account.accountId === filter || account.financialStatementCaption === filter)
    )
    .reduce((sum, account) => ACCOUNT_TYPES_TO_REVERSE.includes(account.accountTypeId) ? sum - account.amount : sum + account.amount, 0);
};

export function calculateAmountByClientAndAccount(
    accounts: TrialBalanceAccountDto[],
    client: string,
    filter: string
  ): number {

    const ACCOUNT_TYPES_TO_REVERSE = [3,4,5,6,8]
    return accounts
      .filter(account => 
        (account.accountId === filter || account.financialStatementCaption === filter) && account.company === client
      )
      .reduce((sum, account) => ACCOUNT_TYPES_TO_REVERSE.includes(account.accountTypeId) ? sum - account.amount : sum + account.amount, 0);
  };

export function calculateAmountByPeriodAndAccountType(
    accounts: TrialBalanceAccountDto[],
    period: string,
    filter: string
  ): number {
    const relevantPeriods = getRelevantPeriods(period);
    const ACCOUNT_TYPES_TO_REVERSE = [3,4,5,6,8]
    return accounts
      .filter(account => 
        relevantPeriods.includes(account.period.toString()) &&
        (account.accountType === filter)
      )
      .reduce((sum, account) => ACCOUNT_TYPES_TO_REVERSE.includes(account.accountTypeId) ? sum - account.amount : sum + account.amount, 0);
  };

export function calculateAmountByClientAndAccountType(
accounts: TrialBalanceAccountDto[],
client: string,
filter: string
): number {

const ACCOUNT_TYPES_TO_REVERSE = [3,4,5,6,8]

return accounts
    .filter(account => 
    
    (account.accountType === filter && account.company === client)
    )
    .reduce((sum, account) => ACCOUNT_TYPES_TO_REVERSE.includes(account.accountTypeId) ? sum - account.amount : sum + account.amount, 0);
};

export function generatePeriodsForYear(year?: number): string[] {
    const periods: string[] = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-based month
    const yearToUse = year || currentYear;
    const yearSuffix = yearToUse.toString().slice(-2); // Extract the last two digits of the year
  
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    // Add month strings up to the current month if the year is the current year
    months.forEach((month, index) => {
      if (yearToUse !== currentYear || index < currentMonth) {
        periods.push(`${month} ${yearSuffix}`);
      }
    });
  
    // Add quarter strings up to the current quarter if the year is the current year
    for (let quarter = 1; quarter <= 4; quarter++) {
      if (yearToUse !== currentYear || quarter <= Math.ceil(currentMonth / 3)) {
        periods.push(`Q${quarter} ${yearSuffix}`);
      }
    }
  
    // Add YTD string if the year is the current year
    if (yearToUse === currentYear) {
      periods.push(`YTD ${yearSuffix}`);
    }
  
    return periods;
  }

  export function hasValuesForAccountTypeOrCaption (
    accounts: TrialBalanceAccountDto[],
    filter: string
  ): boolean {
    const filteredAccounts = accounts.filter(
      account => account.accountType === filter || account.financialStatementCaption === filter
    );
  
    const absoluteSum = filteredAccounts.reduce((sum, account) => sum + Math.abs(account.amount), 0);

    return absoluteSum > 0;
  };

  export function getDistinctAccountsByCaption(
    accounts: TrialBalanceAccountDto[],
    caption: string
  ): { accountId: string, accountName: string }[] {
    const filteredAccounts = accounts.filter(
      account => account.financialStatementCaption === caption
    );
  
    const uniqueAccounts = new Map<string, string>();
  
    filteredAccounts.forEach(account => {
      if (!uniqueAccounts.has(account.accountId)) {
        uniqueAccounts.set(account.accountId, account.accountName);
      }
    });
  
    return Array.from(uniqueAccounts, ([accountId, accountName]) => ({ accountId, accountName }));
  }
