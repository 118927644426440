import {
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DownloadGriegConnectPresentation,
  ReportedValuesDto,
} from "../api/DownloadKeyMetricsPresentation";
import { GetRevenuePerProduct, Product } from "../api/GetRevenue";
import { TransactionQuery } from "../api/GetTrialBalance";
import { PowerpointIcon } from "../icons/PowerpointIcon";
import { formatNumberInThousands } from "../utils/formatting";
import { getCurrentAndFirstPeriodOfYear } from "../utils/timePeriods";
import LineChart from "./LineChart";
import LineChartLoader from "./LineChartLoader";
import SkeletonTable from "./SkeletonTable";
import { useTheme } from "@mui/system";

type ProductValues = {
  values: number[];
  name: string;
  total: number;
};

const RevenueTable: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [distinctProducts, setDistinctProducts] = useState<string[]>([]);
  const [distinctPeriods, setDistinctPeriods] = useState<number[]>([]);
  const [distinctAreas, setDistinctAreas] = useState<string[]>([]);
  const [productValues, setProductValues] = useState<ProductValues[]>([]);
  const [vendorValues, setVendorValues] = useState<ProductValues[]>([]);
  const [distinctVendors, setDistinctVendors] = useState<string[]>([]);
  const [detailsMode, setDetailsMode] = useState<"vendor" | "product">(
    "product"
  );

  const [type, setType] = useState<string>("Alle");
  const [area, setArea] = useState<string>("Samlet");

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [currentPeriod, firstPeriod] = getCurrentAndFirstPeriodOfYear();
        const revenueAccounts = ["3000"];
        const clients = ["Grieg Connect AS"];

        var parameters: TransactionQuery = {
          periodFrom: firstPeriod,
          periodTo: currentPeriod,
          accounts: revenueAccounts,
          clients: clients,
        };

        const data = await GetRevenuePerProduct(parameters);
        setProducts(data);
        let vendors = Array.from(new Set(data.map((item) => item.customer)));
        setDistinctVendors(vendors);
        setDistinctVendors(
          Array.from(new Set(data.map((item) => item.customer)))
        );
        setDistinctProducts(
          Array.from(new Set(data.map((item) => item.groupArea)))
        );
        setDistinctPeriods(Array.from(new Set(data.map((item) => item.month))));
        setDistinctAreas(
          Array.from(new Set(data.map((item) => item.groupArea)))
        );
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  async function handleDownload() {
    let licenses = products.filter((item) => item.groupType === "Lisenser");
    //let services = products.filter((item) => item.groupType === "Tjenester");

    const areas: ReportedValuesDto[] = distinctAreas.map((area) => ({
      values: distinctPeriods.map((period) =>
        licenses
          .filter((item) => item.month === period && item.groupArea === area)
          .reduce((value, product) => (value += product.amount), 0)
      ),
      title: `Monthly recurring revenue - ${area}`,
      periods: distinctPeriods.map((item) => getMonthName(item)),
      axisTitle: "NOK",
      entity: "Grieg Connect AS",
    }));

    const total: ReportedValuesDto = {
      title: "Monthly recurring revenue - totalt",
      axisTitle: "NOK",
      entity: "Grieg Connect AS",
      periods: distinctPeriods.map((item) => getMonthName(item)),
      values: distinctPeriods.map((period) =>
        licenses
          .filter((item) => item.month === period)
          .reduce((value, product) => (value += product.amount), 0)
      ),
    };

    DownloadGriegConnectPresentation([total, ...areas]);
  }

  function calculateDataset() {
    let filteredData = [...products];

    switch (type) {
      case "Lisenser":
        filteredData = filteredData.filter(
          (item) => item.groupType === "Lisenser"
        );
        break;
      case "Tjenester":
        filteredData = filteredData.filter(
          (item) => item.groupType === "Tjenester"
        );
        break;
      case "Alle":
        break;
      default:
        break;
    }

    switch (area) {
      case "Per type":
        let dataSet = distinctAreas.map((area) => ({
          values: distinctPeriods.map((period) =>
            filteredData
              .filter(
                (item) => item.month === period && item.groupArea === area
              )
              .reduce((value, product) => (value += product.amount), 0)
          ),
          name: area,
        }));
        setData(dataSet);
        break;
      case "Samlet":
        let dataSet2 = [
          {
            values: distinctPeriods.map((period) =>
              filteredData
                .filter((item) => item.month === period)
                .reduce((value, product) => (value += product.amount), 0)
            ),
            name: "Samlet",
          },
        ];
        setData(dataSet2);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    calculateDataset();
  }, [products, type, area, distinctPeriods]);

  useEffect(() => {
    let allProducts = [...products];
    switch (type) {
      case "Lisenser":
        allProducts = allProducts.filter(
          (item) => item.groupType === "Lisenser"
        );
        break;
      case "Tjenester":
        allProducts = allProducts.filter(
          (item) => item.groupType === "Tjenester"
        );
        break;
      case "Alle":
        break;
      default:
        break;
    }

    let vendors = distinctVendors
      .map((vendor) => ({
        values: distinctPeriods.map((period) =>
          allProducts
            .filter((item) => item.month === period && item.customer === vendor)
            .reduce((value, item) => (value += item.amount), 0)
        ),
        name: vendor,
        total: allProducts
          .filter((item) => item.customer === vendor)
          .reduce((value, item) => (value += item.amount), 0),
      }))
      .sort((a, b) => b.total - a.total);

    let values = distinctProducts
      .map((product) => ({
        values: distinctPeriods.map((period) =>
          allProducts
            .filter(
              (item) => item.month === period && item.groupArea === product
            )
            .reduce((value, item) => (value += item.amount), 0)
        ),
        name: product,
        total: allProducts
          .filter((item) => item.groupArea === product)
          .reduce((value, item) => (value += item.amount), 0),
      }))
      .sort((a, b) => b.total - a.total);

    setProductValues(values);
    setVendorValues(vendors);
  }, [distinctProducts, products, distinctPeriods, type, distinctVendors]);

  const getMonthName = (month: number): string => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1];
  };

  if (loading) {
    return (
      <Grid item container xs={12} spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={12}>
          <LineChartLoader />
        </Grid>
        <Grid item xs={12}>
          <SkeletonTable />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} sx={{ padding: 2 }}>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12} sm={7} spacing={2}>
          <Grid item>
            <ButtonGroup size="small">
              {["Lisenser", "Tjenester", "Alle"].map((item) => (
                <Button
                  sx={{ fontSize: "0.7rem", width: 100 }}
                  onClick={() => setType(item)}
                  variant={item === type ? "contained" : "outlined"}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonGroup size="small">
              {["Per type", "Samlet"].map((item) => (
                <Button
                  sx={{ fontSize: "0.7rem", width: 100 }}
                  onClick={() => setArea(item)}
                  variant={item === area ? "contained" : "outlined"}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={5}
          justifyContent={"right"}
          spacing={2}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                label="Vis detaljer"
                slotProps={{ typography: { fontSize: "0.7rem" } }}
                control={
                  <Switch
                    value={showDetails}
                    onChange={(event) => setShowDetails(event.target.checked)}
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              style={{ minWidth: 140, marginBottom: 20 }}
            >
              <InputLabel id="details-mode-label">Type</InputLabel>
              <Select
                size="small"
                labelId="details-mode-label"
                value={detailsMode}
                onChange={(e) =>
                  setDetailsMode(e.target.value as "vendor" | "product")
                }
                label="Detaljer"
                sx={{ fontSize: "0.7rem" }}
              >
                <MenuItem sx={{ fontSize: "0.7rem" }} value="product">
                  Område
                </MenuItem>
                <MenuItem sx={{ fontSize: "0.7rem" }} value="vendor">
                  Kunde
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title="Last ned presentasjon">
              <span>
                {/* //add span in order to use tooltip when button is disabled */}
                <IconButton
                  disabled={!products || products.length === 0}
                  onClick={(e) => handleDownload()}
                >
                  <PowerpointIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LineChart
            data={data}
            height={120}
            labels={distinctPeriods.map((item) => getMonthName(item))}
            title="Inntekter per måned"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={showDetails}>
            {(detailsMode === "product" ? productValues : vendorValues)
              .length === 0 ? (
              <Typography sx={{ marginLeft: 10 }} variant="body2">
                Ingen data funnet for nåværende periode.
              </Typography>
            ) : (
              <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: 350 }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={{
                          th: {
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            padding: "10px",
                            borderBottom: "1px solid",
                            borderBottomColor: "lightgray",
                          },
                        }}
                      >
                        <TableCell>
                          {detailsMode === "product" ? "Produkt" : "Kunde"}
                        </TableCell>
                        {distinctPeriods.map((period) => (
                          <TableCell align="right" key={period}>
                            {getMonthName(period)}
                          </TableCell>
                        ))}
                        <TableCell align="right" key={"Totalt"}>
                          Totalt
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(detailsMode === "product"
                        ? productValues
                        : vendorValues
                      ).map((product) => (
                        <>
                          <TableRow
                            key={product.name}
                            sx={{
                              td: {
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "0.7rem",
                                padding: "10px",
                                borderBottom: "none",
                              },
                            }}
                          >
                            <TableCell>
                              {product.name === ""
                                ? "Ingen mapping"
                                : product.name}
                            </TableCell>
                            {product.values.map((periodValue) => (
                              <TableCell sx={{ width: "100px" }} align="right">
                                {formatNumberInThousands(periodValue)}
                              </TableCell>
                            ))}
                            <TableCell sx={{ width: "100px" }} align="right">
                              {formatNumberInThousands(product.total)}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      <TableRow
                        key="Sum"
                        sx={{
                          backgroundColor: "secondary.main",
                          td: {
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "0.7rem",
                            padding: "10px",
                            borderBottom: "none",
                            fontWeight: "bold",
                            color: "primary.contrastText",
                          },
                        }}
                      >
                        <TableCell align="left">Sum</TableCell>
                        {distinctPeriods.map((period, index) => (
                          <TableCell align="right" key={period}>
                            {formatNumberInThousands(
                              (detailsMode === "product"
                                ? productValues
                                : vendorValues
                              ).reduce(
                                (value, item) => (value += item.values[index]),
                                0
                              )
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="right" key={"Totalt"}>
                          {formatNumberInThousands(
                            (detailsMode === "product"
                              ? productValues
                              : vendorValues
                            ).reduce((value, item) => (value += item.total), 0)
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                  </TableHead>
                  <TableBody>{}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RevenueTable;
