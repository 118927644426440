import { DefaultAxiosGetRequest } from "./ApiBase";

export enum AccountTypes {
   ANLEGGSMIDLER = 1,
   OMLOPSMIDLER = 2,
   EGENKAPITAL = 3,
   LANGSIKTIG_GJELD = 4,
   KORTSIKTIG_GJELD = 5,
   DRIFTSINNTEKT = 6,
   DRIFTSKOSTNAD = 7,
   FINANSINNTEKT = 8,
   FINANSKOSTNAD = 9,
   DISPONERING = 10,
   IKKE_MAPPET = 11
}

export interface TrialBalanceAccountDto {
   accountId: string;
   accountName: string;
   amount: number;
   year: number;
   month: number;
   company: string;
   accountType: string;
   accountTypeId: number;
   counterparty: string;
   financialStatementCaption: string | null;
   financialStatementRank: number | null;
   quarter: string;
   period: number;
   type: AccountTypes | null
}

export interface TrialBalanceAccountDtoWithCostCenter extends TrialBalanceAccountDto {
   costCenter: string;
}

export interface TransactionQuery {
   periodFrom: string;
   periodTo: string;
   accounts?: string[];
   clients?: string[];
   clientsDbId?: number[];
   includeProfitLoss?: boolean;
   includeBalanceSheet?: boolean;
   aggregateTimePeriods?: boolean;
   mappingCategory?: number;
}

export interface AccountType {
   id: number;
   name: string;
   accountClasses: AccountClass[];
}

export interface AccountClass {
   id: number;
   name: string;
   rank: number;
   accountTypeId: number;
   accountType: AccountType;
   mappingCategory: MappingCategory;
}

export interface MappingCategory {
   mappingCategoryId: number;
   name: string;
   description: string;
}

export async function GetTrialBalance(parameters: TransactionQuery): Promise<TrialBalanceAccountDto[]> {
   try {
      let result = await DefaultAxiosGetRequest<TrialBalanceAccountDto[], TransactionQuery>(
         `GetTrialBalance`,
         parameters
      );

      result.data.forEach((c) => c.type = c.accountTypeId)

      return result.data;
   } catch (e) {
      throw e;
   }
}

export async function GetTrialBalanceWithCostCenters(parameters: TransactionQuery): Promise<TrialBalanceAccountDtoWithCostCenter[]> {
   try {
      let result = await DefaultAxiosGetRequest<TrialBalanceAccountDtoWithCostCenter[], TransactionQuery>(
         `GetTrialBalanceWithCostCenters`,
         parameters
      );

      result.data.forEach((c) => c.type = c.accountTypeId)

      return result.data;
   } catch (e) {
      throw e;
   }
}

export async function GetAccountingStructure(): Promise<AccountType[]> {
   try {
      let result = await DefaultAxiosGetRequest<AccountType[], undefined>(
         `GetAccountingStructure`, undefined
      );
      return result.data;
   } catch (e) {
      throw e;
   }
}
