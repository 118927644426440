import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import DashboardCard from "../components/DashboardCard";
import RevenueTable from "../components/RevenueTable";
import { AuthContext } from "../context/AuthContext";
import QuarterlyReporting from "./admin/QuarterlyReporting";
import WaterfallChart, {
  WaterfallChartOptions,
  WaterfallValue,
} from "../components/Waterfall";
import Changes from "./reporting/Changes";

export default function Dashboard() {
  const { loading, loggedInUser } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Grid
        sx={{ height: "75vh" }}
        item
        container
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item container xs={12} justifyContent={"center"}>
          <CircularProgress size={80} />
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          <Typography variant="body2">Henter brukerprofil..</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container xs={12} spacing={2}>
      {loggedInUser?.isGriegConnectMember && (
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="dashboard tabs"
          >
            <Tab label="Inntekter" />
            <Tab label="Endringer" />
            <Tab label="Rapportering" />
          </Tabs>
          <Box sx={{ padding: 2 }}>
            {activeTab === 0 && (
              <DashboardCard
                size="large"
                title="Inntekter"
                component={<RevenueTable />}
              />
            )}
            {activeTab === 1 && (
              <DashboardCard
                size="large"
                title="Periodens endringer"
                component={<Changes />}
              />
            )}
            {activeTab === 2 && (
              <DashboardCard
                size="large"
                title="Rapportering"
                component={<QuarterlyReporting client="Grieg Connect AS" />}
              />
            )}
          </Box>
        </Grid>
      )}

      {/* <>
          {(!loggedInUser || loggedInUser?.memberships.length === 0) && (
            <Grid
              item
              container
              xs={12}
              spacing={2}
              sx={{ height: "75vh" }}
              justifyContent={"center"}
              alignContent={"center"}
            >
              {loggedInUser && loggedInUser?.memberships.length === 0 && (
                <>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <Warning sx={{ fontSize: 48 }} />
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <h4>
                      Du har for øyeblikket ingen brukerrettigheter knyttet til
                      siden. Kontakt administrator for å etterspørre
                      rettigheter.
                    </h4>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </> */}
    </Grid>
  );
}
