import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import SkeletonTable from "../SkeletonTable";
import {
  formatNumberInMillions,
  formatNumberInThousands,
} from "../../utils/formatting";
import {
  AccountClass,
  AccountType,
  GetAccountingStructure,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import {
  calculateAmountByClientAndAccount,
  calculateAmountByClientAndAccountType,
  calculateAmountByPeriodAndAccount,
  calculateAmountByPeriodAndAccountType,
  getDistinctAccountsByCaption,
  hasValuesForAccountTypeOrCaption,
} from "../../utils/trialBalanceCalculations";

type ReportingTableProps = {
  periods: string[];
  clients: string[];
  data: TrialBalanceAccountDto[];
  comparisonData: TrialBalanceAccountDto[];
  showColumnsAs: "periods" | "clients";
};

export function ReportingTable({
  periods,
  clients,
  data,
  comparisonData,
  showColumnsAs,
}: ReportingTableProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [structure, setStructure] = useState<AccountType[]>([]);
  const [selectedItem, setSelectedItem] = useState<AccountClass | null>(null);

  const highligtColor = "#faf7f2";

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const data = await GetAccountingStructure();
        setStructure(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const onClickRow = (item: AccountClass) => {
    setSelectedItem((state) => (state === item ? null : item));
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable rows={6} cols={5} />
        ) : (
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ padding: "12px" }}>
              <Table
                sx={{
                  minWidth: 650,
                  fontFamily: "Arial, sans-serif",
                  tr: { fontFamily: "Poppins, sans-serif", fontSize: "0.9rem" },
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "0.8rem",
                        padding: "10px",
                      },
                    }}
                  >
                    <TableCell>TNOK</TableCell>
                    {(showColumnsAs === "periods" ? periods : clients).map(
                      (item) => (
                        <TableCell width={125} align="right">
                          {item}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {structure
                    .filter((accountType) =>
                      hasValuesForAccountTypeOrCaption(
                        showColumnsAs === "periods"
                          ? [...data, ...comparisonData]
                          : data,
                        accountType.name
                      )
                    )
                    .map((accountType) => (
                      <>
                        <TableRow
                          sx={{
                            td: {
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.8rem",
                              padding: "10px",
                              paddingTop: "25px",
                              borderBottom: "none",
                            },
                          }}
                        >
                          <TableCell colSpan={periods.length + 1}>
                            {accountType.name}
                          </TableCell>
                        </TableRow>

                        {accountType.accountClasses
                          .filter((accountClass) =>
                            hasValuesForAccountTypeOrCaption(
                              showColumnsAs === "periods"
                                ? [...data, ...comparisonData]
                                : data,
                              accountClass.name
                            )
                          )
                          .map((item) => (
                            <>
                              <TableRow
                                onClick={() => onClickRow(item)}
                                sx={{
                                  width: "100%",
                                  "&:hover": {
                                    cursor: "pointer",
                                    backgroundColor: "lightgray",
                                  },
                                  td: {
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "0.8rem",
                                    padding: "10px",
                                    borderBottom:
                                      selectedItem === item
                                        ? "1x solid lightgray"
                                        : "none",
                                  },
                                }}
                              >
                                <TableCell>{item.name}</TableCell>
                                {(showColumnsAs === "periods"
                                  ? periods
                                  : clients
                                ).map((col, index) => (
                                  <TableCell align="right">
                                    {formatNumberInThousands(
                                      showColumnsAs === "periods"
                                        ? calculateAmountByPeriodAndAccount(
                                            [...data, ...comparisonData],
                                            col,
                                            item.name
                                          )
                                        : calculateAmountByClientAndAccount(
                                            data,
                                            col,
                                            item.name
                                          )
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                              {selectedItem &&
                                selectedItem === item &&
                                getDistinctAccountsByCaption(
                                  showColumnsAs === "periods"
                                    ? [...data, ...comparisonData]
                                    : data,
                                  selectedItem.name
                                ).map((item) => (
                                  <TableRow
                                    sx={{
                                      width: "100%",
                                      td: {
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "0.8rem",
                                        padding: "10px",
                                        borderBottom: "none",
                                        fontStyle: "italic",
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {"   " +
                                        item.accountId +
                                        " " +
                                        item.accountName}
                                    </TableCell>
                                    {(showColumnsAs === "periods"
                                      ? periods
                                      : clients
                                    ).map((col, index) => (
                                      <TableCell align="right">
                                        {formatNumberInThousands(
                                          showColumnsAs === "periods"
                                            ? calculateAmountByPeriodAndAccount(
                                                [...data, ...comparisonData],
                                                col,
                                                item.accountId
                                              )
                                            : calculateAmountByClientAndAccount(
                                                data,
                                                col,
                                                item.accountId
                                              )
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </>
                          ))}
                        <TableRow
                          sx={{
                            td: {
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "0.8rem",
                              padding: "10px",
                              borderBottom: "none",
                              fontWeight: "bold",
                              backgroundColor: highligtColor,
                            },
                          }}
                        >
                          <TableCell>{`Sum ${accountType.name.toLowerCase()}`}</TableCell>
                          {(showColumnsAs === "periods"
                            ? periods
                            : clients
                          ).map((col) => (
                            <TableCell align="right">
                              {formatNumberInThousands(
                                showColumnsAs === "periods"
                                  ? calculateAmountByPeriodAndAccountType(
                                      [...data, ...comparisonData],
                                      col,
                                      accountType.name
                                    )
                                  : calculateAmountByClientAndAccountType(
                                      data,
                                      col,
                                      accountType.name
                                    )
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
