import React from "react";
import { Line } from "react-chartjs-2";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   ActiveElement,
   ChartEvent,
} from "chart.js";

// Register the required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartProps {
   data: { name: string; values: number[] }[];
   labels: string[]; // Labels for the X-axis
   title: string;
   width?: number;
   height?: number;
   onPointClick?: (data: { label: string; value: number }) => void;
}

function roundToNearest(value: number): number {
   if (value < 1) {
      return Math.ceil(value); // Small numbers (< 1) should be rounded up to the nearest whole
   }
   const magnitude = Math.pow(10, Math.floor(Math.log10(value))); // Get magnitude (e.g. 10, 1000, etc.)
   return Math.ceil(value / magnitude) * magnitude; // Round to nearest magnitude
}

const LineChart: React.FC<LineChartProps> = ({ data, labels, title, width, height, onPointClick }) => {
   const allValues = data.flatMap((item) => item.values);
   const maxValue = Math.max(...allValues);
   const maxChartValue = roundToNearest(maxValue * 1.1); // 110% of the largest value

   const chartRef = React.useRef<any>(null); // Create a ref for the chart

   // Transform input data into chart data
   const chartData = {
      labels: labels,
      datasets: data.map((item, index) => ({
         label: item.name,
         data: item.values,
         borderColor: `hsl(${index * 60}, 70%, 50%)`, // Generate different colors for each line
         backgroundColor: `hsl(${index * 60}, 70%, 50%, 0.1)`,
         fill: false,
         tension: 0.4, // Line curve tension
      })),
   };

   const options = {
      responsive: true,
      plugins: {
         legend: {
            position: "bottom" as const,
            display: data.length > 1, // Only show legend if there are multiple lines
         },
         title: {
            display: true,
            text: title,
         },
      },
      scales: {
         x: {
            title: {
               display: true,
               text: "Periode",
            },
         },
         y: {
            title: {
               display: true,
               text: "Beløp",
            },
            beginAtZero: true, // Starts the y-axis at 0
            max: maxChartValue,
         },
      },
      onClick: (event: ChartEvent, elements: ActiveElement[]) => {
         if (elements.length > 0) {
            const element = elements[0]; // Get the clicked element
            const datasetIndex = element.datasetIndex; // Which dataset was clicked
            const dataIndex = element.index; // Index of the data point in the dataset

            const clickedLabel = labels[dataIndex]; // Get the X-axis label
            const clickedValue = data[datasetIndex].values[dataIndex]; // Get the Y-axis value

            // Trigger the custom onPointClick function with the clicked data point
            onPointClick &&
               onPointClick({
                  label: clickedLabel,
                  value: clickedValue,
               });
         }
      },
      // Change cursor to pointer when hovering over a data point
      onHover: onPointClick
         ? (event: ChartEvent, elements: ActiveElement[]) => {
              const chart = chartRef.current;
              if (elements.length) {
                 chart.canvas.style.cursor = "pointer"; // Change cursor to pointer
              } else {
                 chart.canvas.style.cursor = "default"; // Revert to default cursor
              }
           }
         : undefined,
   };

   return <Line ref={chartRef} data={chartData} options={options} width={width} height={height} />;
};

export default LineChart;
