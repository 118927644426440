import {
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GetRevenuePerProduct, Product } from "../../api/GetRevenue";
import { TransactionQuery } from "../../api/GetTrialBalance";
import LineChartLoader from "../../components/LineChartLoader";
import WaterfallChart, {
  WaterfallChartOptions,
  WaterfallValue,
} from "../../components/Waterfall";
import { getCurrentAndFirstPeriodOfYear } from "../../utils/timePeriods";

interface DistinctProductCustomer {
  id: number;
  customer: string;
}

interface WaterfallChanges extends WaterfallValue {
  products: Product[];
}

interface Changes {
  churn: {
    value: number;
    products: Product[];
  };
  growth: {
    value: number;
    products: Product[];
  };
  newCustomers: {
    value: number;
    products: Product[];
  };
}

const getDistinctCombinations = (
  products: Product[]
): Set<DistinctProductCustomer> => {
  const combinations = new Set<DistinctProductCustomer>();

  products.forEach((product) => {
    combinations.add({ id: product.id, customer: product.customer });
  });

  return combinations;
};

const productExists = (
  product: Product,
  combinations: Set<DistinctProductCustomer>
): boolean => {
  for (const combination of combinations) {
    if (
      combination.id === product.id &&
      combination.customer === product.customer
    ) {
      return true;
    }
  }
  return false;
};

export default function Changes() {
  const [distinctAreas, setDistinctAreas] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPeriodProducts, setCurrentPeriodProducts] = useState<Product[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<WaterfallChanges[]>([]);
  const [mode, setMode] = useState<
    "churn" | "price increases" | "new customers"
  >();

  const onBarClick = (value: WaterfallValue) => {
    setMode(
      value.name.toLowerCase() as "churn" | "price increases" | "new customers"
    );
  };

  const [options, setOptions] = useState<WaterfallChartOptions>({
    startingValue: 0,
    startingLabel: "MRR - fjorår",
    direction: "vertical",
    onBarClick: onBarClick,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [currentPeriod, firstPeriod] = getCurrentAndFirstPeriodOfYear();
        const revenueAccounts = ["3000"];
        const clients = ["Grieg Connect AS"];

        var parametersFirstPeriod: TransactionQuery = {
          periodFrom: firstPeriod,
          periodTo: firstPeriod,
          accounts: revenueAccounts,
          clients: clients,
        };

        var parametersSecondPeriod: TransactionQuery = {
          periodFrom: currentPeriod,
          periodTo: currentPeriod,
          accounts: revenueAccounts,
          clients: clients,
        };

        const startingData = await GetRevenuePerProduct(parametersFirstPeriod);
        const comparisonData = await GetRevenuePerProduct(
          parametersSecondPeriod
        );

        setProducts(startingData);
        setCurrentPeriodProducts(comparisonData);
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const licenseProducts = products.filter(
      (item) => item.groupType === "Lisenser"
    );
    const licenseCurrentPeriodProducts = currentPeriodProducts.filter(
      (item) => item.groupType === "Lisenser"
    );

    const distinctProductsFirstPeriod =
      getDistinctCombinations(licenseProducts);
    const distinctProductsSecondPeriod = getDistinctCombinations(
      licenseCurrentPeriodProducts
    );

    const churn: WaterfallChanges = {
      name: "Churn",
      value: 0,
      products: [] as Product[],
    };

    const growth: WaterfallChanges = {
      name: "Price increases",
      value: 0,
      products: [] as Product[],
    };

    const newCustomers: WaterfallChanges = {
      name: "New customers",
      value: 0,
      products: [] as Product[],
    };

    for (const product of licenseProducts) {
      if (!productExists(product, distinctProductsSecondPeriod)) {
        churn.value -= product.amount;
        churn.products.push(product);
      }
    }

    for (const product of licenseCurrentPeriodProducts) {
      if (!productExists(product, distinctProductsFirstPeriod)) {
        newCustomers.value += product.amount;
        newCustomers.products.push(product);
      } else {
        const previousProduct = products.find(
          (p) => p.id === product.id && p.customer === product.customer
        );
        if (previousProduct) {
          const diff = product.amount - previousProduct.amount;
          growth.value += diff;
          growth.products.push(product);
        }
      }
    }

    const waterfallOptions: WaterfallChartOptions = {
      startingValue: licenseProducts.reduce(
        (acc, product) => acc + product.amount,
        0
      ),
      startingLabel: "Previous period",
      direction: "vertical",
      onBarClick: onBarClick,
    };

    setValues([growth, churn, newCustomers]);
    setOptions(waterfallOptions);
  }, [products, currentPeriodProducts]);

  if (loading) return <LineChartLoader />;

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <WaterfallChart options={options} values={values} />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={mode !== undefined}>
          <TableContainer>
            <Table
              size="small"
              stickyHeader
              sx={{ td: { borderBottom: "none" } }}
            >
              <TableHead>
                <TableRow sx={{ th: { fontWeight: "bold" } }}>
                  <TableCell>Type</TableCell>
                  <TableCell>Kunde</TableCell>
                  <TableCell>Produkt</TableCell>
                  <TableCell align="right">Beløp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.length > 2 &&
                  (mode === "price increases"
                    ? values[0].products
                    : mode === "churn"
                    ? values[1].products
                    : mode === "new customers"
                    ? values[2].products
                    : []
                  )
                    .sort((a, b) =>
                      a.groupArea > b.groupArea
                        ? 1
                        : a.groupArea < b.groupArea
                        ? -1
                        : b.amount - a.amount
                    )
                    .map((product: Product) => (
                      <TableRow>
                        <TableCell>{product.groupArea}</TableCell>
                        <TableCell>{product.customer}</TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">
                          {parseInt(product.amount.toFixed(0)).toLocaleString(
                            "en-US"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                <TableRow
                  sx={{
                    td: {
                      fontWeight: "bold",
                      borderTop: "1px solid",
                      borderColor: "lightgray",
                    },
                  }}
                >
                  <TableCell>Sum</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {values.length > 2 &&
                      parseInt(
                        (mode === "price increases"
                          ? values[0].products
                          : mode === "churn"
                          ? values[1].products
                          : mode === "new customers"
                          ? values[2].products
                          : []
                        )
                          .reduce((acc, product) => acc + product.amount, 0)
                          .toFixed(0)
                      ).toLocaleString("en-US")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Grid>
    </Grid>
  );
}
